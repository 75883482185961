<template>
  <main :class="display">
    <div :class="`page-error ${display ? 'tile' : ''}`">
      <h1>
        <i class="fa fa-exclamation-circle"></i> Error 404: Page not found
      </h1>
      <p>The page you have requested is not found.</p>
      <p>
        <a class="btn btn-primary" href="javascript:window.history.back();"
          >Go Back</a
        >
      </p>

      <!-- <div
        v-touch:drag="swipeHandler"
        :class="`touch ${theColor}`"
        :style="`transform:translateX(${x}%)`"
      >
        Swipe Here now
      </div>

      <div id="touch" class="blue">GSAP Swipe Here now</div> -->
    </div>
  </main>
</template>
<script>
import { gsap, CSSPlugin, Draggable } from "gsap/all";
gsap.registerPlugin(CSSPlugin);
gsap.registerPlugin(Draggable);
export default {
  name: "404",
  props: {
    display: {
      type: String,
    },
  },
  data() {
    return {
      theColor: "green",
      x: 0,
    };
  },
  mounted() {
    gsap.from(".app-header__logo", { duration: 0.2, scale: 0.6, opacity: 0 });

    if (document.querySelector("#touch")) {
      Draggable.create("#touch", {
        // bounds: ".c-cards-col",
        edgeResistance: 0.65,
        type: "x",
        // inertia: true,
        // zIndexBoost: false,
        // bounds: document.querySelector(".tile"),
        onClick: function () {
          console.log("clicked");
        },
        onPress: function () {
          console.log(this);
          console.log(this.x, this.y, this.rotation);
        },
        onDragStart: function () {
          console.log("Drag start");
        },
        onDrag: function () {
          console.log(this.x, this.y, this.rotation);
        },
        onDragEnd: function () {
          console.log("Drag end");
        },
      });
    }
  },
  methods: {
    // calcPercent(x, y) {
    //   Xpercent = Math.round((x / w) * 100);
    //   Ypercent = Math.round((y / h) * 100);
    //   console.log(Xpercent, Ypercent);
    // },
    swipeHandler(direction) {
      console.log(direction.movementX); // May be left / right / top / bottom
      // this.theColor = direction === "left" ? "blue" : "green";
      this.x += direction.movementX;
    },
  },
};
</script>
